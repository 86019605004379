<template lang="pug">
  div.fullsize
    router-view
</template>
<script>
export default {
  mounted() {
    this.$store.commit("auth/SET_TOKE_FAILED", false)
  }
}
</script>

<style>
.avatar2 {
  width: 350px;
  border-radius: 6px;
  display: block;
  margin: 0px auto;
}
.back-trasnp {
  background: #f0f0f0c2;
}
.fullsize {
  height: 100vh;
  width: 100vw;
}
</style>